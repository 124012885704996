<template>
  <div
    class="is-flex container-general-error">
    <!--init svg img-->
    <div
      class="image-svg mr-4">
      <svg width="240" height="110" viewBox="0 0 267 110" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M70.2791 108V88.6604H82.9707V68.112H70.2791V2.23636H41.7229L0.172913 66.2989V88.6604H46.86V108H70.2791ZM23.1387 67.5076L46.2556 31.0947H46.86V68.112H23.1387V67.5076Z" fill="black"/>
        <path d="M91.8686 54.816C91.8686 71.436 95.3437 84.732 102.143 94.5529C108.942 104.374 119.367 109.36 133.267 109.36C147.168 109.36 157.593 104.374 164.392 94.5529C171.191 84.732 174.666 71.436 174.666 54.816C174.666 38.3471 171.191 25.0511 164.392 15.2302C157.593 5.40928 147.168 0.423279 133.267 0.423279C119.367 0.423279 108.942 5.40928 102.143 15.2302C95.3437 25.0511 91.8686 38.3471 91.8686 54.816ZM150.945 54.816C150.945 78.5373 146.11 88.8115 133.267 88.8115C120.425 88.8115 115.59 78.5373 115.59 54.816C115.59 31.3969 120.576 20.9716 133.267 20.9716C145.959 20.9716 150.945 31.3969 150.945 54.816Z" fill="black"/>
        <path d="M253.978 108V88.6604H266.67V68.112H253.978V2.23636H225.422L183.872 66.2989V88.6604H230.559V108H253.978ZM206.838 67.5076L229.955 31.0947H230.559V68.112H206.838V67.5076Z" fill="black"/>
      </svg>
    </div>
    <!-- fin svg -->
    <div
      class="container-rigth-error">

      <p class="principal-text text-basier-medium dark-blue-emitus">
        Esta pagina no ha sido encontrada
      </p>

      <p
        class="secundary-text text-nunito dark-gray-emitus">
        Lo sentimos no hemos encontrado la url a la que te estas intentando dirigir  
      </p>

      <button
        class="icon-button-medium text-basier-medium"
        @click="goHome"
        >
        Ir al inicio
      </button>

    </div>
  </div>
</template>
<script>
export default {
  name: 'NotFound',
  components: {},
  metaInfo: {
    title: 'emitus | Not Found'
  },
  methods: {
    goHome () {
      this.$router.push('/')
    }
  }
}
</script>
<style scoped>

.container-general-error {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 320px;
  margin: auto;
  justify-content: center;
  align-content: center;
  height: 580px;
}

.image-svg {
  margin-top: 50px;
}
.image-svg svg {
  width: 150px;
  margin-left: 27px;
}

.container-rigth-error {
  margin-top: 25px;
  margin-left: 20px;
}

.container-rigth-error .principal-text {
  font-size: 25px;
  max-width: 280px;
}

.container-rigth-error .secundary-text {
  margin-top: 42px;
  font-size: 15px;
}

.icon-button-medium {
  margin-top: 60px;
}

@media (min-width: 959px) {
.container-general-error {
  flex-direction: row;
  max-width: 876px;
  align-items: center;
  height: 640px;
}

.image-svg svg {
  width: 309px;
  height: 456px;
}

.container-rigth-error {
  margin-top: 173px;
  margin-left: 29px;
}
.container-rigth-error .principal-text {
  font-size: 35px;
  max-width: 386px;
}

}
</style>
